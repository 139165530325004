:root {
  --white: #FFFFFF;
  --black: #000000;
  --blue: #0069FF;
  --red: #E21755;
  --formLabel: #646464;
  --lightgray: #A9A9A9;
  --bottom-navigation-border: #EBEBEB;
  --header-height: 65px;
  --menu-height: 55px;
  --link-text: #0069FF;
  --link-text-disabled: #C5C5C5;
  --chatlist-item-border: #EBEBEB;
  --button-background: #0069FF;
  --button-background-disabled: #F3F3F3;
  --button-text-disabled: #9B9B9B;
}

body {
  font-family: Roboto, sans-serif;
  margin: 0;
}


/* ---------- HEADER PROPERTIES ---------- */

.headerContainer {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  border-bottom-color: var(--bottom-navigation-border);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  height: var(--header-height);
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
}

.offlineIndicator {
  float: right;
  color: var(--red);
  padding-left: 0.5rem;
  cursor: pointer;
}

.offlineIndicator:hover {
  color: #c40d0d;
}

.connecting-indicator {
  font-family: 'Material Icons';
  font-size: 1.5rem;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  color: var(--lightgray);
  margin: 0 0.5rem
}

.connecting-indicator::before {
  content: "\e63e";
  animation: wifi-connecting 1.5s linear 0s infinite;
}

@keyframes wifi-connecting {
  33% {
    content: "\e4ca";
  }
  66% {
    content: "\e4d9";
  }
  100% {
    content: "\e63e";
  }
}


.notificationContainer {
  float: left;
  height: var(--header-height);
  /* position: absolute; */
  display: flex;
  align-items: center;
  /* margin-left: 8vh; */
}

.notificationButton {
  padding-top: 6px;
  padding-right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.refreshButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.arrowContainer {
  float: left;
  height: var(--header-height);
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.tabButton {
  padding-top: 11px;
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: auto;
}

.arrowButton {
  padding-top: 4px;
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: auto;
}
.tabButtonFooter {
  padding-top: 10px;
  padding-left: 15vw;
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: auto;
}
.menuFooter {
  padding-left: 12vw;
}

.logoContainer {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 6.35vw; */
}

.logo {
  height: 44px;
  width: 44px;
}

.plusContainer {
  float: right;
  height: var(--header-height);
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
}

.refreshContainer {
  float: right;
  height: var(--header-height);
  display: flex;
  align-items: center;
}

.plusButton {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.globalDNDIndicator {
  float: right;
  height: var(--header-height);
  display: flex;
  align-items: center;
  padding-right: 10px;
  color: #646464;
  background-color: var(--white);
  border-bottom-color: var(--bottom-navigation-border);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  cursor: default;
}

.titleContainer {
  height: var(--header-height);
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 26px;
  color: var(--black);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineButtons:focus {
  outline: 0;
}

.messageMenu {
  color: var(--black);
  font-size: 18px;
}

.iconBlue24 {
  color: var(--blue);
  font-size: 24px;
}

.iconDisabled24 {
  color: var(--link-text-disabled);
  font-size: 24px;
}

.iconBlue18 {
  color: var(--blue);
  font-size: 18px;
}

.helpVideoArrow {
  color: var(--blue);
  font-size: 18px;
  -ms-transform: translate(0px, 12px);
  -webkit-transform: translate(0px, 12px);
  -moz-transform: translate(0px, 12px);
  -o-transform: translate(0px, 12px);
  transform: translate(0px, 12px);
  padding-right: 5px;
}

.emailArrow {
  color: var(--blue);
  font-size: 18px;
  -ms-transform: translate(12px, 0px);
  -webkit-transform: translate(12px, 0px);
  -moz-transform: translate(12px, 0px);
  -o-transform: translate(12px, 0px);
  transform: translate(12px, 0px);
}

.iconRed18 {
  color: var(--red);
  font-size: 18px;
}

.iconRed24 {
  color: var(--red);
  font-size: 24px;
}

.iconGray {
  color: var(--lightgray);
}

.iconGray16 {
  color: var(--lightgray);
  font-size: 16px;
}


.iconSeenByText {
  color: var(--formLabel);
  font-size: 12px;
  padding-left: 5px;
}

#chatInfo.material-icons-outlined {
  color: var(--blue);
  font-size: 24px;
}

.blinking {
  -webkit-animation-name: blinkingIcon; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
  animation-name: blinkingIcon;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  color: var(--blue);
  font-size: 24px;
}

@keyframes blinkingIcon{
  50%{ opacity: 0; }
}

.headerButtonAnimate {
  -webkit-animation-name: headerIcon; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: .45s; /* Safari 4.0 - 8.0 */
  -webkit-animation-iteration-count: 1; /* Safari 4.0 - 8.0 */
  animation-name: headerIcon;
  animation-duration: .45s;
  animation-iteration-count: 1;
  color: var(--blue);
  font-size: 24px;
}

@keyframes headerIcon{
  50%{ opacity: 0.5; }
}

/* ---------- END HEADER PROPERTIES ---------- */


.menuBarContainer {
  height: var(--menu-height);
}
.selectedMenuLabel {
  color: black;
  font-weight: bold;
  font-size: 12px;
  align-self: center;
}
.menuLabel {
  color: #9B9B9B;
  font-size: 12px;
  align-self: center;
  text-align: center;
}
.selectedMenuLabelFooter {
  color: black;
  font-weight: bold;
  font-size: 12px;
  align-self: center;
  padding-left: 13vw;
}
.menuLabelFooter {
  color: #9B9B9B;
  font-size: 12px;
  align-self: center;
  padding-left: 13vw;
}
.menuBarWrapper {
  flex-direction: column-reverse;
  align-self: center;
  position: fixed;
  bottom: 0;
  background-color: var(--white);
  right: 0;
  width: 100%;
  border-top-width: 2px;
  border-top-color: var(--chatlist-item-border);
  border-top-style: solid;
}
.logoutButtonContainer {
  float: right;
  height: var(--header-height);
  display: flex;
  align-items: center;
  padding-right: 10px;
  background-color: var(--white);
  border-bottom-color: var(--bottom-navigation-border);
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

/* ---------- CHAT INPUT PROPERTIES ---------- */

.mentionsWrapper {
  flex-direction: column-reverse;
  align-self: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
}

.wrapper {
  background-color: var(--white);
  flex-direction: column-reverse;
  align-self: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
}

.wrapperLeftOpen {
  width: 75vw;
}

.wrapperRightOpen {
  right: 25vw;
  width: 75vw;
}

.wrapperBothOpen {
  right: 25vw;
  width: 50vw;
}

.border {
  border-top-width: 2px;
  border-top-color: var(--chatlist-item-border);
  border-top-style: solid;
  width: 100%;
}

.buttonWrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-top: auto;
  flex-direction: row;
  display: flex;
}

.sendButtonWrapper {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.attachFileButton {
  float: right;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 5px;
  margin-top: 10px;
}

.messageInput {
  font-family: Roboto, sans-serif;
  flex-direction: row;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  width: 65%;
  resize: none;
  border: none;
  outline: none;
  background-color: var(--white);
}

.noteInput {
  font-family: Roboto, sans-serif;
  flex-direction: row;
  font-size: 14px;
  margin: 15px;
  width: 90%;
  resize: none;
  border: none;
  outline: none;
  background-color: var(--white);
}

.messageInputEmoji {
  font-family: Roboto, sans-serif;
  float: left;
  flex-direction: row;
  font-size: 32px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  background-color: var(--white);
}

::-moz-selection {
  background: lightcoral;
}
::-webkit-selection {
  background: lightcoral;
}
::selection {
  background: lightcoral;
}
:-ms-selection {
  background: lightcoral;
}
:-moz-selection {
  background: lightcoral;
}

TextArea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  background-color: var(--white);
  border: none;
}
TextArea::-moz-placeholder { /* Firefox 19+ */
  background-color: var(--white);
  border: none;
}
TextArea:-ms-input-placeholder { /* IE 10+ */
  background-color: var(--white);
  border: none;
}
TextArea:-moz-placeholder { /* Firefox 18- */
  background-color: var(--white);
  border: none;
}

.chatInputButton {
  color: var(--white);
  text-transform: none;
  background-color: var(--button-background);
}

.chatInputButtonDisabled {
  color: var(--button-text-disabled);
  text-transform: none;
  background-color: var(--button-background-disabled);
}

.emojiPickerButton {
  float: right;
  cursor: pointer;
  background-color: var(--white);
  border: 0;
  padding-left: 0;
  margin-top: 15px;
}

.emojiPickerButtonDisabled {
  float: right;
  background-color: var(--white);
  border: 0;
  padding-left: 0;
  margin-top: 15px;
  margin-bottom: 5px;
}

.askAlanButton {
  float: right;
  cursor: pointer;
  background-color: var(--white);
  border: 0;
  outline: 0;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 0;
}

.askAlanButtonDisabled {
  float: right;
  background-color: var(--white);
  border: 0;
  outline: 0;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 0;
}

.fontIconColor {
  color: var(--blue);
}

.fontIconColorDisabled {
  color: var(--link-text-disabled);
}

.mentionUser:hover {
  background-color: #F4F4F4;
  border-radius: 6px;
  transition: background-color 300ms cubic-bezier(0.17, 0.89, 0.32, 1);
}

/* ---------- END CHAT INPUT PROPERTIES ---------- */









/* ---------- SIDEBAR PROPERTIES ---------- */

.sidebar {
  margin-top: var(--header-height);
  width: 25vw;
}
.sidebarTablet {
  margin-top: var(--header-height);
  width: 40vw;
}

.sidebar.caseList {
  width: 100%;
}

.leftOpen {
  width: 75%;
}

.rightOpen {
  right: 25%;
  width: 75%;
}

.bothOpen {
  right: 25%;
  width: 50%;
}

.iconPerson {
  -ms-transform: translateY(4px);
  -webkit-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
  margin-left: 4px;
  font-size: 18px;
  color: var(--lightgray);
  display: inline-block;
}

.back {
  -ms-transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
  font-size: 18px;
  color: var(--blue);
  display: inline-block;
}

.backLight {
  -ms-transform: translateY(-2px);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
  font-size: 18px;
  color: var(--white);
  display: inline-block;
}

/* ---------- END SIDEBAR PROPERTIES ---------- */





/* ---------- OTHER PROPERTIES ---------- */

.verticalCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 25vw;
}

.emailError {
  -ms-transform: translateY(4px);
  -webkit-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
  margin-left: 4px;
  font-size: 18px;
  color: var(--red);
  display: inline-block;
}

.emailVerified {
  -ms-transform: translateY(4px);
  -webkit-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
  margin-left: 4px;
  font-size: 18px;
  color: var(--blue);
  display: inline-block;
}


.cellLast {
  float: right;
}

.rowDescription {
  color: var(--lightgray);
  font-size: 12px;
  margin-right: 4px;
}

.caseListRow {
  transition: background-color 300ms cubic-bezier(0.17, 0.89, 0.32, 1);
}

.caseListRow:nth-child(even) {
  background-color: #f6f7f8;
}

.caseListRow:hover {
  background-color: #F4F4F4;
  outline: none;
}

.chatlistRow {
  transition: background-color 300ms cubic-bezier(0.17, 0.89, 0.32, 1);
}

.chatlistRow:hover {
  background-color: #F4F4F4;
  outline: none;
}

#chatListTitleColumn > * {
  padding-bottom: 5px;
}

.closeButton:focus {
  outline: none;
}

#clearButton {
  transition: transform .1s cubic-bezier(0, 0.52, 0, 1);
}

#clearButton.true {
  transform: translate3d(0vw, 0, 0);
}

#clearButton.true:focus {
  outline: 0;
}

#clearButton.false {
  transform: translate3d(-100vw, 0, 0);
}

#paddingLeft {
  transition: transform .1s cubic-bezier(0, 0.52, 0, 1);
}

#paddingLeft.true {
  margin-left: 60px;
  transform: translate3d(30px, 0, 0);
}

#paddingLeft.false {
  margin-left: 30px;
}

#pinContainer {
  display: inline-flex;
  align-items: center;
}

#pinContainer.true {
  display: inline-flex;
  align-items: center;
  overflow-x: hidden;
}

#loading {
  -webkit-animation: rotation 2s infinite linear;
}

.overflowUsersTip {
  max-width: 175px;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@media print {
  html, body {
    display: none;  /* hide whole page */
  }
}

.emojiButton {
  background-color: var(--white);
}

.emojiButtonSelected {
  background-color: #F4F4F4;
  border-radius: 14px;
}

.emojiButton:hover {
  background-color: #F4F4F4;
  border-radius: 14px;
}

.messageMenuOption {
  background-color: var(--white);
}

.messageMenuOption:hover {
  background-color: #F4F4F4;
  border-radius: 8px;
}

.bullet {
  font-size: 10px;
  color: #00a79d;
}

.replyAlertClose {
  font-size: 14px;
  color: var(--formLabel);
  margin-left: 10px;
}

.offer {
  font-size: 75px;
  color: var(--lightgray);
}

.highlightClass {
  background-color: lightcoral;
  border-radius: 2px;
}

.error-page-container {
  max-width: 88rem;
}

.error-buttons-grid {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr; /* Default to a single column */
  gap: 2rem;
  margin: 2.5rem 0;
}

.error-page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0;
  flex-wrap: wrap;
  gap: 2rem;
}

.error-page-content hr {
  display: none;
}

/* ---------- END OTHER PROPERTIES ---------- */





/* Styling for when the screens is tablet size (between 425px and 768 px) */
@media screen and (min-width: 475px) and (max-width: 769px) {

  @media (orientation: portrait){
    .verticalCenter {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 50vw;
    }

    .sidebar{
      width: 40vw;
    }

    .cellLast {
      float: left;
    }

    .rowDescription {
      float: left;
    }

    .wrapperLeftOpen {
      width: 67vw;
    }

  }

  @media (orientation: landscape){
    .sidebar{
      width: 25vw;
    }
    .sidebarTablet{
      width: 35vw;
    }

    .verticalCenter {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 25vw;
    }
  }

  .inputWrapper {
    background-color: var(--white);
    flex-direction: row;
    align-self: center;
    position: fixed;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    width: 100%;
  }

  .messageInput {
    font-family: Roboto, sans-serif;
    flex-direction: row;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
    width: 60%;
    resize: none;
    border: none;
    outline: none;
    background-color: var(--white);
  }

  TextArea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    background-color: var(--white);
    border: none;
  }
  TextArea::-moz-placeholder { /* Firefox 19+ */
    background-color: var(--white);
    border: none;
  }
  TextArea:-ms-input-placeholder { /* IE 10+ */
    background-color: var(--white);
    border: none;
  }
  TextArea:-moz-placeholder { /* Firefox 18- */
    background-color: var(--white);
    border: none;
  }

}

/* Styling for when the screens is phone size (less than 475px) */
@media screen and (max-width: 475px) {

  .verticalCenter {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 75vw;
  }

  .sidebar {
    margin-top: var(--header-height);
    width: 100vw;
  }

  .inputWrapper {
    background-color: var(--white);
    flex-direction: row;
    align-self: center;
    position: fixed;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    width: 100vw;
  }

  .messageInput {
    font-family: Roboto, sans-serif;
    flex-direction: row;
    font-size: 14px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 20px;
    width: 50%;
    resize: none;
    border: none;
    outline: none;
    background-color: var(--white);
  }

  TextArea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    background-color: var(--white);
    border: 0;
  }
  TextArea::-moz-placeholder { /* Firefox 19+ */
    background-color: var(--white);
    border: 0;
  }
  TextArea:-ms-input-placeholder { /* IE 10+ */
    background-color: var(--white);
    border: 0;
  }
  TextArea:-moz-placeholder { /* Firefox 18- */
    background-color: var(--white);
    border: 0;
  }

}

@media screen and (min-width: 640px) {
  .error-buttons-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 980px) {
  .error-page-container {
    margin: 2.5rem;
  }

  .error-page-content {
    flex-direction: row;
  }

  .error-page-content hr {
    display: block;
  }

  .error-page-message {
    width: 35%;
    min-width: 12.5rem;
  }
}

@media screen and (min-width: 425px) and (max-width: 1300px) {
  .wrapperLeftOpen {
    width: 60vw;
  }
}

@media screen and (min-width: 425px) and (max-width: 769px) {
  .wrapperLeftOpen {
    width: 65vw;
  }
}

@media screen and (min-width: 425px) and (max-width: 1300px) and (min-height: 625px) {
  .wrapperLeftOpen {
    width: 60vw;
  }
}

/* Case List Dashboard */
.case-table {
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
}

.case-table th {
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #666;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
}

.case-table th + th {
  border-left: 1px solid #e5e5e5;
}

/* Limit the width of the case tags column */
.case-table th:nth-child(3) {
  width: 10%;
}

.case-table th:nth-child(7) {
  width: 10%;
}

.case-table .sortable {
  cursor: pointer;
}

.case-table .sortable:hover,
.case-table .sortable:hover svg {
  color: #60a0fb;
}

.case-table .sortable svg {
  color: var(--button-text-disabled);
  margin-left: 0.25rem;
}

.case-table .sortable.sorted,
.case-table .sortable.sorted svg {
  color: var(--link-text);
}

.tag-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.tag-icon-container svg {
  width: 30px;
  height: 100%;
  display: inline-flex;
  padding: 2px;
  fill: currentColor;
}

.tag-container-button {
  width: 100%;
}

.tag-column-container {
  max-height: 105px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--formLabel);
    border-radius: 4px;
    opacity: 0.50;
  }
}

.case-tag-item {
  max-width: 11vw;
  height: 25px;
  font-size: 15px;
  background-color: #BFBFBF;
  color: #2F2F2F;
  border: 1px solid #8d8686;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-overflow: ellipsis;
  margin: 0 10px;
  margin-bottom: 2px;
}

.case-last-note-container {
  max-height: 116px;
  overflow: auto;
  font-size: 0.8rem;
  color: #666;
  margin: 4px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.case-tag-item:hover {
  text-overflow: clip;
  transform: scale(1.05);
}

.scrolling-tag:hover {
  animation: label-scroll 2s linear forwards;
}

@keyframes label-scroll {
  0% {
    text-indent: 0;
  }

  100% {
    text-indent: -100%;
  }
}


